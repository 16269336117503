import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const WhyHearMe = () => (
  <Layout>
    <Seo title="About" />
    {/* <!-- This example requires Tailwind CSS v2.0+ --> */}

    <div className="max-w-2xl px-6 py-16 mx-auto space-y-12">
      <article className="space-y-8 bg-coolGray-100 text-coolGray-900">
        <div className="space-y-6">
          <h1 className="text-4xl font-bold md:tracking-tight md:text-5xl text-emerald-500">
            Why I Developed HearMe for $0.
          </h1>
          <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-coolGray-600">
            <div className="flex items-center md:space-x-2">
              <img
                src="https://i.ibb.co/nmJgC6w/ignite-youth-0650-1.png"
                alt=""
                className="w-4 h-4 border rounded-full bg-coolGray-500 border-coolGray-300"
              />
              <p className="text-sm">George Chakama • October 5th 2021</p>
            </div>
            <p className="flex-shrink-0 mt-3 text-sm md:mt-0">
              4 min read • 1,570 views
            </p>
          </div>
        </div>
        <div className="text-coolGray-800">
          <p className="my-4">
            Yesterday on my way to grab some groceries I met a girl who was mute
            (can not speak ). She was hitch-hiking trying to go to the shops
            too, when I stopped my car to pick her up I realized she doesn't
            speak and I felt disappointed in not being able to understand sign
            language. I know some basic sign language but I struggle a lot to
            understand what has been expressed.
          </p>
          <p className="my-4">
            It was an awkward drive to the shops because she could understand me
            and I could not understand her because I was driving and she
            couldn't conversate with me.
          </p>
          <p className="my-4">
            Being a curious developer I knew that a solution needed to be found
            so that I can talk to her and also other people who face the same
            challenge.
          </p>
          <p className="my-4">
            I decided to develop a small project that will not only help her but
            the community at large. I will be using my own personal resources to
            fund and develop the project because I really want to give back to
            the community.
          </p>

          <p className="my-4">
            The solution I will develop should be free for everybody to use and
            user-friendly for even my grandparents to use with ease.{" "}
          </p>
          <h2>What My Solution will Cover</h2>
          <p className="my-4">
            A conversation with someone who can not speak requires focus. For
            individuals to understand what they are saying they have to look at
            them, bummer right. When you're driving you can not have a
            conversation and that's just awkward.
          </p>

          <p className="my-4">
            {" "}
            It's not just with driving but also when you are doing other jobs
            that can not allow your attention to be diverted e.g Operating a
            Machine, doing surgery, and running/ exercising.{" "}
          </p>

          <p className="my-4">
            Since sign language requires focus, communicating in the dark will
            definitely be difficult. This is because you can not really see.{" "}
          </p>

          <p className="my-4">
            Weather conditions also affect sign language. When you are stuck in
            a dusty storm your visibility is limited and won't be able to see
            but hear.
          </p>
        </div>
      </article>
      <div>
        <div className="flex flex-wrap py-6 space-x-2 border-t border-dashed border-coolGray-600">
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #StartUp
          </span>
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #SAAS
          </span>
          <span className="px-3 py-1 rounded-sm bg-green-400 text-coolGray-50">
            #HearMe
          </span>
        </div>
        <div className="space-y-2">
          <h4 className="text-lg font-semibold">Related posts</h4>
          <ul className="ml-4 space-y-1 list-disc">
            <li>
              <a
                rel="noopener noreferrer"
                href="/interviewsawards"
                className="hover:underline"
              >
                TV interviews and Awards
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="/projectcontributors"
                className="hover:underline"
              >
                Project Contributors
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <nav
      aria-label="breadcrumb"
      className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
    >
      <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
        <li className="flex items-center">
          <a
            rel="noopener noreferrer"
            href="/"
            title="Back to homepage"
            className="flex items-center hover:underline"
          >
            Home
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/know"
            className="flex items-center px-1 capitalize hover:underline font-bold "
          >
            About
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/started"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Features
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/contact"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Contact
          </a>
        </li>
      </ol>
      <footer className="animate-pulse">
        © {new Date().getFullYear()}, An Idea by
        {` `}
        <a className="text-center text-sm" href="https://www.chakama.co.zw">
          George Chakama
        </a>
      </footer>
    </nav>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p className="my-4">
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p className="my-4">
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default WhyHearMe
